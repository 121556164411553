/**
 * 文件路径补全
 * @param {String} url 文件路径
 */
export function combileCDN(url) {
	const cdn = "https://wximg.aliyinba.com/";

	if (url === "") return url;
	if (Object.prototype.toString.call(url) !== "[object String]") return url;
	if (url.indexOf("/src") === 0) return url;
	if (url.indexOf("/assets") === 0) return url;

	if (
		/^(https|http):\/\//i.test(url) ||
		/^(data|http|https):/i.test(url) ||
		url.indexOf("http://") >= 0 ||
		url.indexOf("https://") >= 0
	) {
		return url;
	}

	url = url
		.replace(/\.+\//gi, "/")
		.replace(/~+/gi, "/")
		.replace(/\/+/gi, "/")
		.replace(/\//, "");

	return `${cdn}${url}`;
}

/**
 * base64 转 文件Blob 对象
 * @param {String} dataurl base64路径
 */
export function dataUrlToBlob(dataurl) {
	let arr = dataurl.split(","),
		mine = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mine });
}
