/** request请求 */

import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
import setting from "./settings";
import { getAdminInfo } from "./localStorage";

console.log(setting.API_URL)

const request = axios.create({
  baseURL: setting.API_URL,
  timeout: 1000 * 60 * 2,
});

/** 请求头设置 */
request.interceptors.request.use(
  (config) => {
    const adminInfo = getAdminInfo();
    config.headers["token"] = adminInfo ? adminInfo.token : "";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/** 相应拦截 */
request.interceptors.response.use(
  (response) => {
    if(response.headers["content-type"] ==="application/vnd.ms-excel"){
     return response;
    }
    const res = response.data;
    if (res.statuscode === 200) {
      return Promise.resolve(res);
    } else {
      if (res.statuscode === 416) {
        const adminInfo = getAdminInfo();
        const path="/login";
        if(adminInfo!==null && adminInfo.accType===0){
          window.location = "https://www.165183.com/Login.html";
        }else{
          router.push(path)
        }
      }
      Message({
        message: res.msg,
        type: "error",
        duration: 2000,
      });
      return Promise.reject(res);
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
    });
    return Promise.reject(error);
  }
);

export default request;
