import TabsView from "@/layouts/TabsView.vue";

/**
 * 静态路由
 */
export const constantRoutes = [
  {
    path: "/login",
    name: "登录页",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/hdmpage",
    name: "活动装修",
    component: () => import("@/views/actEditor/actEditor.vue"),
  }
  ,
  {
    path: "/tranLogin",
    name: "请稍后...",
    component: () => import("@/views/tranLogin.vue"),
  }
  ,
  {
    path: "/act",
    name: "活动专题",
    component: () => import("@/views/special/index.vue"),
  },
  {
    path: "/mblist",
    name: "模板列表",
    component: () => import("@/views/special/mblist.vue"),
  }
];

/**
 * 主办方路由
 */
export const hostRoutes = [
  {
    path: "/host",
    redirect: "/host",
    component: TabsView,
    children: [
      {
        path: "/host",
        name: "主办方工作台",
        meta: {
          sideMenu: true,
          menuGruop:'host',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/host.vue"),
      },
      
      {
        path: "/activityList",
        name: "活动管理",
        meta: {
          sideMenu: true,
          menuGruop:'activity',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/activityManage/activityList.vue"),
      },
      {
        path: "/activitySet",
        name: "活动设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/activityManage/activitySet.vue"),
      },
      {
        path: "/createActitity",
        name: "创建活动",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/createActivity/createActitity.vue"),
      },
      {
        path: "/ticketList",
        name: "门票列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/ticket/ticketList.vue"),
      },
      {
        path: "/ticketItem",
        name: "报名字段",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/ticket/ticketItem.vue"),
      },
      {
        path: "/ticketBanner",
        name: "门票Banner",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/ticket/ticketBanner.vue"),
      },
      {
        path: "/share",
        name: "分享设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/activityManage/share.vue"),
      },
      {
        path: "/promte",
        name: "推广海报",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/activityManage/promte.vue"),
      },
      {
        path: "/commissionSet",
        name: "佣金营销设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/marketingTools/commissionSet.vue"),
      },
      {
        path: "/gameList",
        name: "游戏管理",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/game/gameList.vue"),
      },
      {
        path: "/addGame",
        name: "编辑游戏",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/game/addGame.vue"),
      },
      {
        path: "/prizeRecord",
        name: "中奖记录",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/game/prizeRecord.vue"),
      },
      {
        path: "/prizeList",
        name: "奖品列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/game/prizeList.vue"),
      },
      {
        path: "/editPrize",
        name: "编辑奖品",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/game/editPrize.vue"),
      },
      {
        path: "/hotProductList",
        name: "爆款产品列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/hotProduct/list.vue"),
      },
      {
        path: "/editHotProduct",
        name: "编辑爆款产品",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/hotProduct/edit.vue"),
      },
      {
        path: "/hotProductSet",
        name: "爆款全局设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/hotProduct/banner.vue"),
      },
      {
        path: "/groupProductList",
        name: "团购产品列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/groupProduct/list.vue"),
      },
      {
        path: "/editGroupProduct",
        name: "编辑团购产品",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/groupProduct/edit.vue"),
      },
      {
        path: "/GroupProductSet",
        name: "拼团全局设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/groupProduct/banner.vue"),
      },
      {
        path: "/helpProductList",
        name: "助力产品列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/helpProduct/list.vue"),
      },
      {
        path: "/editHelpProduct",
        name: "编辑助力产品",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/helpProduct/edit.vue"),
      },
      {
        path: "/helpPrizeList",
        name: "助力奖品列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () =>
          import("@/views/product/helpProduct/helpPrizeList.vue"),
      },
      {
        path: "/editHelpPrize",
        name: "编辑助力奖品",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/helpProduct/editPrize.vue"),
      },
      {
        path: "/helpSet",
        name: "活动助力全局设定",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/product/helpProduct/helpSet.vue"),
      },
      {
        path: "/tempList",
        name: "活动模板",
        meta: {
          sideMenu: true,
          menuGruop:'temp',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/createActivity/tempList.vue"),
      },
      {
        path: "/smsBuyList",
        name: "短信管理",
        meta: {
          sideMenu: true,
          menuGruop:'sms',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/smsManage/buyList.vue"),
      },
      {
        path: "/readCashList",
        name: "阅读奖励金记录",
        meta: {
          sideMenu: true,
          menuGruop:'read',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/readCash/readCashList.vue"),
      },
      {
        path: "/readRecharge",
        name: "阅读奖励金充值",
        meta: {
          sideMenu: false,
          menuGruop:'read',
        },
        component: () => import("@/views/readCash/readRecharge.vue"),
      },   
      {
        path: "/readSet",
        name: "阅读营销设置",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/marketingTools/readSet.vue"),
      },
      {
        path: "/productList",
        name: "产品列表",
        meta: {
          sideMenu: true,
          menuGruop:'baseProduct',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/product/baseProduct/list.vue"),
      },
      {
        path: "/editProduct",
        name: "编辑产品",
        meta: {
          sideMenu: false,
          menuGruop:'baseProduct',
        },
        component: () => import("@/views/product/baseProduct/edit.vue"),
      },
      {
        path: "/verification",
        name: "验票核销",
        meta: {
          sideMenu: true,
          menuGruop:'ver',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/verification/verification.vue"),
      },
      {
        path: "/hostInfo",
        name: "主办方详情",
        meta: {
          sideMenu: false,
        },
        component: () => import("@/views/hostInfo.vue"),
      },
      {
        path: "/editHost",
        name: "主办方资料",
        meta: {
          sideMenu: true,
          menuGruop:'hostInfo',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/editHost.vue"),
      },

      {
        path: "/balanceRecord",
        name: "余额明细",
        meta: {
          sideMenu: false,
        },
        component: () => import("@/views/userCenter/balanceRecord.vue"),
      },
      {
        path: "/customer",
        name: "客户详情",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/customer/index.vue"),
      },
      {
        path: "/shopRefund",
        name: "售后详情",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/refund/shopRefund.vue"),
      }
      ,
      {
        path: "/ticketRefund",
        name: "售后详情",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/refund/ticketRefund.vue"),
      },
      {
        path: "/hotOrder",
        name: "爆款订单",
        meta: {
          sideMenu: true,
          menuGruop:'hotOrder',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/order/hotOrder/list.vue"),
      }
      ,
      {
        path: "/hotOrderInfo",
        name: "爆款订单详情",
        meta: {
          sideMenu: false,
          menuGruop:'hotOrder',
        },
        component: () => import("@/views/order/hotOrder/info.vue"),
      },
      {
        path: "/groupOrder",
        name: "团购订单",
        meta: {
          sideMenu: true,
          menuGruop:'groupOrder',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/order/groupOrder/list.vue"),
      }
      ,
      {
        path: "/groupOrderInfo",
        name: "团购订单详情",
        meta: {
          sideMenu: false,
          menuGruop:'groupOrder',
        },
        component: () => import("@/views/order/groupOrder/info.vue"),
      },
      {
        path: "/ticketOrder",
        name: "门票订单",
        meta: {
          sideMenu: true,
          menuGruop:'ticketOrder',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/order/ticketOrder/list.vue"),
      },
      {
        path: "/helpOrder",
        name: "助力订单",
        meta: {
          sideMenu: true,
          menuGruop:'helpOrder',
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/order/helpOrder/list.vue"),
      }
      ,
      {
        path: "/helpOrderInfo",
        name: "助力订单详情",
        meta: {
          sideMenu: false,
          menuGruop:'helpOrder',
        },
        component: () => import("@/views/order/helpOrder/info.vue"),
      },
      {
        path: "/dataAnalysis",
        name: "数据分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/dataAnalysis.vue"),
      }
      ,
      {
        path: "/readChart",
        name: "访问分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/readChart.vue"),
      }
      ,
      {
        path: "/readDetailList",
        name: "访问量统计",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/readDetailList.vue"),
      }
      ,
      {
        path: "/visitorDetailList",
        name: "访客统计",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/visitorDetailList.vue"),
      }
      ,
      {
        path: "/visitorChart",
        name: "访客分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/visitorChart.vue"),
      }
      ,
      {
        path: "/applyDetailList",
        name: "门票购买统计",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/applyDetailList.vue"),
      }
      ,
      {
        path: "/applyChart",
        name: "门票分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/applyChart.vue"),
      },
      {
        path: "/orderList",
        name: "订单统计",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/orderList.vue"),
      },
      {
        path: "/orderChart",
        name: "订单分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/orderChart.vue"),
      }
      ,
      {
        path: "/verAnalysis",
        name: "核销分析",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/dataAnalysis/verAnalysis.vue"),
      }
      ,
      {
        path: "/comment",
        name: "评论管理",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/comment/comment.vue"),
      }
      ,
      {
        path: "/commission",
        name: "佣金管理",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/commission/commission.vue"),
      },
      {
        path: "/sendList",
        name: "短信群发",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/sendSms/sendList.vue"),
      },
      {
        path: "/sendMessage",
        name: "短信群发",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/sendSms/sendMessage.vue"),
      },
      {
        path: "/smsRecord",
        name: "短信发送记录",
        meta: {
          sideMenu: false,
          menuGruop:'sms',
        },
        component: () => import("@/views/sendSms/smsRecord.vue"),
      },
      {
        path: "/joinerList",
        name: "参与者列表",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/join/joinerList.vue"),
      }
      ,
      {
        path: "/cashAuthor",
        name: "提现实名认证",
        meta: {
          sideMenu: false,
          menuGruop:'activity',
        },
        component: () => import("@/views/cashAuthor/cashAuthor.vue"),
      }   
    ],
  },
  {
    path: "/:path(.*)*",
    name: "错误",
    component: () => import("@/views/error/404.vue"),
  }    
];

/**
 * 代理商路由
 */
export const branchRoutes = [
  {
    path: "/sponsor",
    redirect: "/sponsor",
    component: TabsView,
    children: [
      {
        path: "/sponsor",
        name: "主办方管理",
        meta: {
          sideMenu: true,
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/sponsorList.vue"),
      },
      {
        path: "/hostApply",
        name: "主办方申请管理",
        meta: {
          sideMenu: true,
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/hostApply/hostApply.vue"),
      },
      {
        path: "/newHost",
        name: "新增主办方",
        meta: {
          sideMenu: true,
          icon: "el-icon-receiving",
        },
        component: () => import("@/views/addHost.vue"),
      },
    ],
  },
  {
    path: "/:path(.*)*",
    name: "错误",
    component: () => import("@/views/error/404.vue"),
  }  
];
