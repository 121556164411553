import { branchRoutes, hostRoutes } from "@/router/router";
import router from "@/router/index";
import { getAdminInfo } from "./localStorage";

/**
 * 设置导航菜单
 */
export function addRoutes() {
  localStorage.removeItem("HDB-Menus");
  const adminInfo = getAdminInfo();
  if (adminInfo.accType === 0) {
    router.addRoutes(branchRoutes);
    const menus = branchRoutes[0].children.filter((e) => e.meta.sideMenu);
    localStorage.setItem("HDB-Menus", JSON.stringify(menus));
  } else {
    router.addRoutes(hostRoutes);
    const menus = hostRoutes[0].children.filter((e) => e.meta.sideMenu);
    localStorage.setItem("HDB-Menus", JSON.stringify(menus));
  }
}


