import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";
import '@/utils/permission';
import { combileCDN } from "./utils/filePath";
import mixin from '@/mixin/tools';
import moment from 'moment';
import VueTinymce from '@packy-tang/vue-tinymce';
import store from "./store";

// 设置为中文
moment.locale('zh-cn')
Vue.prototype.moment = moment

Vue.use(ElementUI);
Vue.mixin(mixin);
Vue.use(VueTinymce);

Vue.prototype.combileCDN = combileCDN;
Vue.config.productionTip = false;


// 富文本提交去掉cdn
Vue.prototype.RpsCdn = function(_html) {
  if (_html) {
    var reg = RegExp(
      // eslint-disable-next-line no-useless-escape
      /(src|poster|href)=\"(.*?)\"|(src|poster|href)='(.*?)'|(src|poster|href)=(.*?)/gi
    )
    var _src = _html.match(reg)
    var srcArr = []
    if (_src) {
      for (var i = 0; i < _src.length; i++) {
        if (_src[i]) {
          srcArr.push(
            _src[i]
              .replace(/^(src=|poster=|href=)('|")/i, '')
              .replace(/('|")$/i, '')
          )
        }
        // expr.html(_html);
      }
      if (srcArr.length > 0) {
        for (var j = 0; j < srcArr.length; j++) {
          if (/http(s?):\/\/wximg.165183.com\/Files\//i.test(srcArr[j])) {
            _html = _html.replace(
              /http(s?):\/\/wximg.165183.com\/Files\//gi,
              '/Files/'
            )
          } else if (
            /http(s?):\/\/wximg.165183.com\/UploadFiles\//i.test(srcArr[j])
          ) {
            _html = _html.replace(
              /http(s?):\/\/wximg.165183.com\/UploadFiles\//gi,
              '/UploadFiles/'
            )
          } else if (
            /http(s?):\/\/wximg.165183.com\/UserFiles\//i.test(srcArr[j])
          ) {
            _html = _html.replace(
              /http(s?):\/\/wximg.165183.com\/UserFiles\//gi,
              '/UserFiles/'
            )
          } else if (
            /http(s?):\/\/wximg.165183.com\/..\/UserFiles\//i.test(srcArr[j])
          ) {
            _html = _html.replace(
              /http(s?):\/\/wximg.165183.com\/..\/UserFiles\//gi,
              '../UserFiles/'
            )
          }
        }
      }
    }
  }

  return _html
}

Vue.directive('onlyInteger', {
  inserted: function(el, bind) {
    el.addEventListener('keypress', function(e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      let re = /\d/
      switch (bind.value) {
        case 'int':
          re = /\d/
          checkNumber(re, charcode, e)
          break
        case 'float':
          re = /\d/
          checkFloat(el, e, charcode, re)
          break
        default:
          checkNumber()
      }
    })
  }
})

// 整数
const checkNumber = (re, charcode, e) => {
  if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
    if (e.preventDefault) {
      e.preventDefault()
    } else {
      e.returnValue = false
    }
  }
}
// 小数
const checkFloat = (el, e, charcode, reg) => {
  if (charcode === 46) {
    if (el.children[0].value.includes('.')) {
      e.preventDefault()
    }
    return
  } else if (
    !reg.test(String.fromCharCode(charcode)) &&
    charcode > 9 &&
    !e.ctrlKey
  ) {
    if (e.preventDefault) {
      e.preventDefault()
    } else {
      e.returnValue = false
    }
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
