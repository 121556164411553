import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isDragging: false, // 是否在组件拖拽中，这里是添加组件
    cmpIndex: -1, // 组件索引，给拖拽组件添加唯一索引，方便后续插入组件
    addCmp: "", // 添加的组件名
  },
  getters: {
    getIsDragging: (state) => {
      return state.isDragging;
    },
    getCmpIndex: (state) => {
      return state.cmpIndex;
    },
    getAddCmp: (state) => {
      return state.addCmp;
    },
  },
  mutations: {
    setIsDragging(state, payload) {
      state.isDragging = payload;
    },
    setCmpIndex(state, index) {
      state.cmpIndex = index;
    },
    setAddCmp(state, cmp) {
      state.addCmp = cmp;
    },
  },
});

export default store;