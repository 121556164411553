
import request from "@/utils/request";

/**
 * 智慧云码授权登录
 */
export function authorLogin(data) {
  return request({
    url: "ACT/Admin/AuthorLogin",
    method: "post",
    data,
  });
}


/**
 * 获取主办方列表
 * @param {*} data
 * @returns
 */
export function getHostList(data) {
  return request({
    url: 'ACT/Admin/GetHostList',
    method: 'get',
    params: { ...data }
  })
}

/**
* 增加活动同时在线数量
* @param {*} data
* @returns
*/
export function addHostSurNum(data) {
  return request({
    url: 'ACT/Admin/AddHostSurNum',
    method: 'post',
    data
  })
}

/**
* 禁用/启用主办方账户
* @param {*} data
* @returns
*/
export function editHostStatus(data) {
  return request({
    url: 'ACT/Admin/EditHostStatus',
    method: 'post',
    data
  })
}

/**
* 主办方续期
* @param {*} data
* @returns
*/
export function editHostDate(data) {
  return request({
    url: 'ACT/Admin/EditHostDate',
    method: 'post',
    data
  })
}


/**
 * 切换到代理商账户
 */
 export function switchBranch(data) {
  return request({
    url: "ACT/Admin/SwitchBranch",
    method: "post",
    data,
  });
}

/**
 * 代理商代管理主办方
 */
 export function manageHost(data) {
  return request({
    url: "ACT/Admin/ManageHost",
    method: "post",
    data,
  });
}