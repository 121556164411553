import moment from "moment";

export default {
	//格式化时间
	formatDate(date, pattern = "YYYY-MM-DD HH:mm:ss") {
		if (!date) return "";
		return moment(date).format(pattern);
	},

	/**
	 * 计算元素位置与尺寸
	 * @param {Object} styleObj 样式集合
	 */
	getElementStyle(styleObj) {
		const style = {};
		for (const key in styleObj) {
			if (Object.hasOwnProperty.call(styleObj, key)) {
				const val = styleObj[key];
				style[key] = typeof val === "number" ? `${val}px` : val;
			}
		}
		return style;
	},

	/**
	 * 深度拷贝
	 * @param {Object} obj 需要拷贝对象
	 * @returns {Object}
	 */
	deepClone(obj) {
		if ([null, undefined, NaN, false].includes(obj)) return obj;
		if (typeof obj !== "object" && typeof obj !== "function") {
			// 原始类型直接返回
			return obj;
		}
		var o = this.isArray(obj) ? [] : {};

		for (const i in obj) {
			if (Object.hasOwnProperty.call(obj, i)) {
				o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
			}
		}
		return o;
	},

	/**
	 * 验证是否为数组
	 * @param {Array} arr
	 * @returns
	 */
	isArray(arr) {
		return Object.prototype.toString.call(arr) === "[object Array]";
	},

	/**
	 * 双数组取差集，返回第一个数组中所独有的
	 * @param {Array} arr1
	 * @param {Array} arr2
	 * @returns {Array}
	 */
	difference(arr1, arr2) {
		const s = new Set(arr2);
		return arr1.filter((x) => !s.has(x));
	},
};

/** 工具集 */

/**
 * 验证是否为数组
 * @param {Array} arr
 * @returns
 */
export function isArray(arr) {
	return Object.prototype.toString.call(arr) === "[object Array]";
}

/**
 * 深度拷贝
 * @param {Object} obj 需要拷贝对象
 * @returns {Object}
 */
export function deepClone(obj) {
	if ([null, undefined, NaN, false].includes(obj)) return obj;
	if (typeof obj !== "object" && typeof obj !== "function") {
		// 原始类型直接返回
		return obj;
	}
	var o = isArray(obj) ? [] : {};

	for (const i in obj) {
		if (Object.hasOwnProperty.call(obj, i)) {
			o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
		}
	}
	return o;
}

/**
 * 深度合并
 * @param {Object} target
 * @param {Object} source
 * @returns {Object}
 */
export function deepMerge(target = {}, source = {}) {
	target = deepClone(target);
	if (typeof target !== "object" || typeof source !== "object") return false;
	for (var prop in source) {
		// eslint-disable-next-line no-prototype-builtins
		if (!source.hasOwnProperty(prop)) continue;
		if (prop in target) {
			if (typeof target[prop] !== "object") {
				target[prop] = source[prop];
			} else {
				if (typeof source[prop] !== "object") {
					target[prop] = source[prop];
				} else {
					if (target[prop].concat && source[prop].concat) {
						target[prop] = target[prop].concat(source[prop]);
					} else {
						target[prop] = deepMerge(target[prop], source[prop]);
					}
				}
			}
		} else {
			target[prop] = source[prop];
		}
	}
	return target;
}

/**
 * 金钱千位分割补全两位小数
 * 10000 => "10,000.00"
 * @param {number | String} money 转化金额
 * @param {Number} places 保留小数金额
 */
export function toThousandFilter(money, places = 2) {
	const zero = "0.00";
	if (isNaN(money) || money === "") return zero;

	if (money && money != null) {
		money = `${money}`;
		const left = money.split(".")[0];
		let right = money.split(".")[1];
		right = right
			? right.length >= places
				? "." + right.substr(0, places)
				: "." + right + "0".repeat(places - right.length)
			: "." + "0".repeat(places);
		var temp = left
			.split("")
			.reverse()
			.join("")
			.match(/(\d{1,3})/g);
		return (
			(Number(money) < 0 ? "-" : "") +
			temp.join(",").split("").reverse().join("") +
			right
		);
	} else if (money === 0) {
		return zero;
	} else {
		return zero;
	}
}

/**
 * 前置补0
 * @param {Number | String} num 需要补全的字符
 * @param {Number} targetLength 补全的位数
 * @returns {String}
 */
export function padZero(num, targetLength = 2) {
	let str = num + "";
	while (str.length < targetLength) {
		str = "0" + str;
	}
	return str;
}

/**
 * 定时器组件专属，构建时间对象
 * @param {Number} time
 * @returns {Object}
 */
export function parseTimeData(time) {
	const SECOND = 1000; // 秒
	const MINUTE = 60 * SECOND; // 分钟
	const HOUR = 60 * MINUTE; // 小时
	const DAY = 24 * HOUR; // 天

	const days = Math.floor(time / DAY);
	const hours = Math.floor((time % DAY) / HOUR);
	const minutes = Math.floor((time % HOUR) / MINUTE);
	const seconds = Math.floor((time % MINUTE) / SECOND);
	const milliseconds = Math.floor(time % SECOND);

	return {
		days,
		hours,
		minutes,
		seconds,
		milliseconds,
	};
}

/**
 * 格式化时间
 * @param {String} format 时间格式
 * @param {Object} timeData 时间对象
 * * @param {Number} timeData.days
 * * @param {Number} timeData.hours
 * * @param {Number} timeData.minutes
 * * @param {Number} timeData.seconds
 * * @param {Number} timeData.milliseconds
 * @returns {String}
 */
export function parseFormat(format, timeData) {
	const { days } = timeData;
	let { hours, minutes, seconds, milliseconds } = timeData;

	if (format.indexOf("DD") === -1) {
		hours += days * 24;
	} else {
		format = format.replace("DD", padZero(days));
	}

	if (format.indexOf("HH") === -1) {
		minutes += hours * 60;
	} else {
		format = format.replace("HH", padZero(hours));
	}

	if (format.indexOf("mm") === -1) {
		seconds += minutes * 60;
	} else {
		format = format.replace("mm", padZero(minutes));
	}

	if (format.indexOf("ss") === -1) {
		milliseconds += seconds * 1000;
	} else {
		format = format.replace("ss", padZero(seconds));
	}

	return format.replace("SSS", padZero(milliseconds, 3));
}

/**
 * 验证时间是否出现偏差
 * @param {Number} time1 number
 * @param {Number} time2
 * @returns {Boolean}
 */
export function isSameSecond(time1, time2) {
	return Math.floor(time1 / 1000) === Math.floor(time2 / 1000);
}

/** 时间转换 */
export function timeFormat(dateTime = null, fmt = "yyyy-mm-dd") {
	// 如果为null,则格式化当前时间
	if (!dateTime) dateTime = Number(new Date());
	// 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
	if (dateTime.toString().length === 10) dateTime *= 1000;
	const date = new Date(dateTime);
	let ret;
	const opt = {
		"y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"h+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"s+": date.getSeconds().toString(), // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (const k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(
				ret[1],
				ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
			);
		}
	}
	return fmt;
}

/**
 * 时间戳转为多久之前
 * @param {String} timestamp 时间戳
 * @param {String | Boolean} format 如果为时间格式字符串，超出一定时间范围，返回固定的时间格式；
 * 如果为布尔值false，无论什么时间，都返回多久以前的格式
 */
export function timeFrom(dateTime = null, format = "yyyy-mm-dd") {
	// 如果为null,则格式化当前时间
	if (!dateTime) dateTime = Number(new Date());
	// 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
	if (dateTime.toString().length === 10) dateTime *= 1000;
	const timestamp = +new Date(Number(dateTime));

	const timer = (Number(new Date()) - timestamp) / 1000;

	// 如果小于5分钟,则返回"刚刚",其他以此类推
	let tips = "";
	switch (true) {
		case timer < 300:
			tips = "刚刚";
			break;
		case timer >= 300 && timer < 3600:
			tips = parseInt(timer / 60) + "分钟前";
			break;
		case timer >= 3600 && timer < 86400:
			tips = parseInt(timer / 3600) + "小时前";
			break;
		case timer >= 86400 && timer < 2592000:
			tips = parseInt(timer / 86400) + "天前";
			break;
		default:
			// 如果format为false，则无论什么时间戳，都显示xx之前
			if (format === false) {
				if (timer >= 2592000 && timer < 365 * 86400) {
					tips = parseInt(timer / (86400 * 30)) + "个月前";
				} else {
					tips = parseInt(timer / (86400 * 365)) + "年前";
				}
			} else {
				tips = timeFormat(timestamp, format);
			}
	}
	return tips;
}

/**
 * 计算元素位置与尺寸
 * @param {Object} styleObj 样式集合
 */
export function getElementStyle(styleObj) {
	const style = {};
	for (const key in styleObj) {
		if (Object.hasOwnProperty.call(styleObj, key)) {
			const val = styleObj[key];
			style[key] = typeof val === "number" ? `${val}px` : val;
		}
	}
	return style;
}

/**
 * 双数组取差集，返回第一个数组中所独有的
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export function difference(arr1, arr2) {
	const s = new Set(arr2);
	return arr1.filter((x) => !s.has(x));
}

const hexList = [];
for (let i = 0; i <= 15; i++) {
	hexList[i] = i.toString(16);
}

/** 生成UUID */
export function buildUUID() {
	let uuid = "";
	for (let i = 1; i <= 36; i++) {
		if (i === 9 || i === 14 || i === 19 || i === 24) {
			uuid += "-";
		} else if (i === 15) {
			uuid += 4;
		} else if (i === 20) {
			uuid += hexList[(Math.random() * 4) | 8];
		} else {
			uuid += hexList[(Math.random() * 16) | 0];
		}
	}
	return uuid.replace(/-/g, "");
}
