/** 全局配置 */

// 环境变量：dev开发环境、test测试环境、pro生产环境
const isDevelop = "test";

const config = {
	dev: () => {
		return {
			API_URL: "http://192.168.1.181:8005/api/",
			CDN_URL: "https://wximg.aliyinba.com",
			appDomain: "http://hdbh5.sousouyin.cn",
		}

	},
  test: () => {
		return {
			API_URL: "http://hdbapi.sousouyin.cn/api/",
			CDN_URL: "https://wximg.aliyinba.com",
			appDomain: "http://hdbh5.sousouyin.cn",
		}

	},
	pro: () => {
    const domainArr=window.location.host.split('.');
		const domain=`${domainArr[1]}.${domainArr[2]}`
		return {
			API_URL: `https://hdbapi.${domain}/api/`,
			CDN_URL:  "https://wximg.aliyinba.com",
			appDomain: `https://hdbH5.${domain}`,
		}
	}
}

export default {
  // 默认标题
  TITLE: "活动宝",

  // 地图key
  MAP_KEY: "A4YBZ-NJB3P-ZHIDM-LX2JH-AUEO2-SMFD3",

  ...config[isDevelop](),

  miniQrcode: true,
  
  // 文件上传路径
  UPLOAD_URL: "https://10000.165183.com/UserControls/Upload_json.ashx?dir=",

};
