<template>
  <div>
    <el-container>
      <el-aside>
        <side-menu />
      </el-aside>
      <el-container>
        <el-header>
          <Head />
        </el-header>
        <el-main>
          <slot></slot>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import Head from "@/layouts/components/Head.vue";
import SideMenu from "./components/SideMenu.vue";

export default {
  name: "LayoutView",
  components: { Head, SideMenu },
  data() {
    return {};
  },
  computed:{
  }
};
</script>

<style lang="scss" scoped>
.el-main{
  padding: 0;
  font-size: 14px;
}
.el-header {
  border-bottom: 1px solid #cecece;
  z-index: 1;
  height: 50px !important;
  .head {
    display: flex;
    display: -webkit-flex;
    height: 50px;
    align-items: center;
  }
}
.el-aside {
  height: 100vh;
  width: 200px !important;
  background-color: #304156;
  z-index: 9999;
}
</style>
