/** 侧方菜单栏 */

<template>
  <el-menu router :default-active="defaultActive">
    <el-menu-item v-for="router in menu" :key="router.path" :index="router.path">
      <template slot="title">
        <i :class="router.meta.icon"></i>
        <span>{{ router.name }}</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      defaultActive: "",
      menu: [],
    };
  },
  watch: {
    $route() {
      this.initActive();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initActive();
    });
  },
  methods: {
    initActive() {
      this.menu.splice(0,this.menu.length);
      const menu =  JSON.parse(localStorage.getItem("HDB-Menus"));

      const route = this.$route;
      let active = "";
      if (menu.length > 0) {
        const page = menu.find((e) => e.meta.menuGruop === route.meta.menuGruop);
        if (page) {
          active = page.path;
        } else {
          active = "/";
        }
      }
      menu.forEach(item=>{
        this.menu.push(item);
      })
      this.defaultActive = active;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  background: #304156;
  height: 100vh;
  .el-menu-item {
    color: #909399;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background: #263445;
    color: #fff;
  }
  .is-active {
    background: #263445;
    color: #fff;
  }
}
</style>
