
/** 本地储存 */

const userKey = "HDB-Admin";

/**
 * 获取登录人信息
 * @returns 
 */
export function getAdminInfo() {
    const adminInfo = localStorage.getItem(userKey);
    if (adminInfo && adminInfo !=="undefined") {
        return JSON.parse(adminInfo);
    }
    return null
}

/**
 * 设置登录人信息
 * @returns 
 */
export function setAdminInfo(value) {
    localStorage.setItem(userKey, JSON.stringify(value))
}

/**
* 删除登录人信息
* @returns 
*/
export function removeAdminInfo() {
    localStorage.removeItem(userKey)
}
