/** 选项卡视图 */
<template>
  <layout-view>
    <div class="tabs-view-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </layout-view>
</template>

<script>
import LayoutView from "@/layouts/LayoutView.vue";
export default {
  name: "TabsView",
  components: { LayoutView},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.tabs-view-content {
  padding: 0;
  margin: 0;
  .content {
    background-color: #ffffff;
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}
</style>
