<template>
  <div class="head">
    <div class="title">{{ route.name }}</div>

    <el-button
      v-if="admin.isAdmin && admin.accType === 1"
      class="manageBtn"
      type="primary"
      plain
      size="mini"
      @click="goManage"
    >
      返回管理主办方
    </el-button>

    <!-- S 个人信息 -->
    <div class="user-info" v-if="admin">
      <el-dropdown type="primary" @command="goOut($event)">
        <div class="label">
          <el-avatar :src="combileCDN(admin.faceImg)" :size="35" />
          <div class="name">{{ admin.userName }}</div>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="login">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- S 个人信息 -->
  </div>
</template>

<script>
import { getAdminInfo, setAdminInfo } from "@/utils/localStorage";
import { switchBranch } from "@/api/admin";
import { resetRouter } from "@/router/index";
import { addRoutes } from "@/utils/permission";

export default {
  name: "headPage",
  data() {
    return {
      route: null,
      admin:{}
    };
  },
  watch: {
    $route: function (newRoute) {
      this.route = newRoute;
      this.admin = getAdminInfo();
    },
  },
  created() {
    this.admin = getAdminInfo();
    this.route = this.$route;
  },
  methods: {
    goOut(e) {
      if (e === "login") {
        this.$router.replace({
          path: "/login",
        });
      }
    },
    async goManage() {
      var res = await switchBranch();
      if (res.statuscode === 200) {
        localStorage.clear();
        resetRouter();
        setAdminInfo(res.data);
        addRoutes();
        this.$router.push({ path: "/sponsor" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  display: -webkit-flex;
  height: 50px;
  align-items: center;
  .title {
    flex: 1;
  }

  .manageBtn {
    margin-right: 20px;
  }

  .user-info {
    .el-avatar {
      margin-top: 2px;
    }
    .label {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
    }
  }
}
</style>
