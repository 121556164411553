import Vue from "vue";
import Router from "vue-router";
import { constantRoutes } from "@/router/router";
import { getAdminInfo } from "@/utils/localStorage";
import { branchRoutes, hostRoutes } from "@/router/router";


Vue.use(Router);

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

/**
 * 路由后置守卫保存当前浏览页面
 */
router.afterEach((to) => {
  if(to.path !== "/login"&&to.path !== "/act"&&to.path !== "/mblist"){
    localStorage.setItem("HDB-NewPagePath", to.path);
  }
});

/**
 * 路由前置守卫
 */
router.beforeEach((to, from, next) => {
  const path = localStorage.getItem("HDB-NewPagePath");
  if (to.path === "/login") {
    localStorage.clear();
    resetRouter();
  }
  document.title = to.name;
  if (from.path==='/' && path) {
    localStorage.removeItem("HDB-NewPagePath");
    const adminInfo = getAdminInfo();
    if(adminInfo!==null){
      if (adminInfo.accType === 0) {
        router.addRoutes(branchRoutes);
      } else {
        router.addRoutes(hostRoutes);
      }
      router.replace({ ...to });
    }
    else{
      if(to.path === "/act" ||to.path === "/mblist"){
        console.log(...to)
        router.replace({ ...to });
      } else{
        router.replace({path:'/login'});
      } 
      
    }
  }
  next();
});

export default router;
